export default [
  // {
  //   header: 'Realtime Monitoring',
  // },
  // {
  //   title: 'Dashboard',
  //   icon: 'MonitorIcon',
  //   route: 'apps-dashboard-view',
  // },
  {
    title: 'Dashboard',
    icon: 'MonitorIcon',
    children: [
      {
        title: 'FTE',
        route: 'apps-fte-list',
      },
      {
        title: 'Dashboard',
        route: 'apps-dashboard-view',
      },
    ],
  },
  {
    title: 'Weekly OPR',
    icon: 'TargetIcon',
    children: [
      {
        title: 'Main KPI',
        route: 'apps-weekly-opr-main-list',
      },
      {
        title: 'Target KPI',
        route: 'apps-weekly-opr-target-list',
        // route: 'misc-coming-soon',
      },
      {
        title: 'Report',
        route: 'apps-weekly-opr-list',
        // route: 'misc-coming-soon',
      },
    ],
  },
  {
    title: 'Monthly Summary',
    icon: 'MonitorIcon',
    route: 'apps-monthly-summary-view',
  },
  {
    title: 'Monitoring',
    icon: 'ActivityIcon',
    children: [
      {
        title: 'CT Monitoring',
        // icon: 'ActivityIcon',
        route: 'apps-ct-monitoring-view',
      },
      {
        title: 'Realtime Monitoring',
        // icon: 'ActivityIcon',
        route: 'apps-realtime-monitoring-view',
      },
      {
        title: 'Grafik MES',
        route: 'apps-grafik-mes-view',
      },
      {
        title: 'Shot Data',
        route: 'apps-shot-data-view',
      },
      {
        title: 'Shiftly Result',
        route: 'apps-shiftly-result-view',
      },
      {
        title: 'Plasticzing & CT',
        // icon: 'ActivityIcon',
        route: 'apps-plasticzing-monitoring-view',
      },
      {
        title: 'Monthly Result',
        route: 'apps-monthly-result-view',
      },
      {
        title: 'Best CT',
        // icon: 'ActivityIcon',
        route: 'apps-best-ct-monitoring-view',
      },
      {
        title: 'CTPP Progress',
        // icon: 'ActivityIcon',
        route: 'apps-ctpp-progress-view',
      },
      {
        title: 'Chillers',
        // icon: 'ActivityIcon',
        route: 'apps-chiller-list',
      },
      {
        title: 'Akses Lampu',
        // icon: 'TrendingUpIcon',
        children: [
          {
            title: 'Master Lampu',
            route: 'apps-master-lampu-list',
          },
          {
            title: 'Akses Lampu',
            route: 'apps-akses-lampu-list',
            // route: 'misc-coming-soon',
          },
        ],
      },
    ],
  },
  // {
  //   header: 'MES Rawdata',
  // },
  {
    title: 'MES Rawdata',
    icon: 'LayersIcon',
    children: [
      {
        title: 'Powermeter',
        route: 'apps-powermeter-view',
      },
      {
        title: 'Mixing',
        route: 'apps-mixing-view',
      },
      {
        title: 'Pelletizer',
        route: 'apps-pelletizer-view',
      },
      // {
      //   title: 'IM Log',
      //   route: 'apps-im-log-view',
      // },
      {
        title: 'IM Log',
        route: 'apps-im-log-list',
      },
      {
        title: 'Material Transport',
        route: 'apps-material-transport-view',
      },
      {
        title: 'Tag Logger',
        route: 'apps-tag-logger-view',
      },
      {
        title: 'Master Produk',
        route: 'apps-master-produk-view',
      },
      {
        title: 'Master Mold',
        route: 'apps-master-mold-raw-view',
      },
      {
        title: 'Reject Line 1-2',
        route: 'apps-reject-line-12-view',
      },
      {
        title: 'Reject Line 3-5',
        route: 'apps-reject-line-35-view',
      },
      {
        title: 'Reject Overflow',
        route: 'apps-reject-overflow-view',
      },
      {
        title: 'Recycle/Unrecycle',
        route: 'apps-recycle-unrecycle-view',
      },
      {
        title: 'Result Dynamic',
        route: 'apps-result-dynamic-view',
      },
      {
        title: 'Master Reject Type',
        route: 'apps-reject-type-view',
      },
      {
        title: 'Master Mesin',
        route: 'apps-master-mesin-view',
      },
      {
        title: 'Master Kardus',
        route: 'apps-master-kardus-view',
      },
    ],
  },
  // {
  //   header: 'Energy',
  // },
  {
    title: 'Energy',
    icon: 'ZapIcon',
    children: [
      {
        title: 'SLD Overview',
        route: 'apps-sld-overview-view',
      },
      {
        title: 'Powermeter Overview',
        route: 'apps-powermeter-overview-view',
      },
      {
        title: 'Improvement Monitor',
        children: [
          {
            title: 'Target Energy',
            route: 'apps-target-energy-list',
          },
          {
            title: 'Energy Before MES',
            route: 'apps-energy-before-mes-list',
          },
          {
            title: 'Report',
            route: 'apps-improvement-monitor-view',
          },
        ],
      },
      {
        title: 'Energy Report',
        route: 'apps-energy-report-view',
      },
      {
        title: 'Energy Report YTD',
        route: 'apps-energy-report-ytd-view',
      },
      {
        title: 'Tag Setting',
        route: 'apps-tag-setting-list',
      },
      {
        title: 'Energy Report Tag',
        route: 'apps-energy-report-tag-view',
      },
      {
        title: 'Energy Report Tag YTD',
        route: 'apps-energy-report-tag-ytd-view',
      },
      {
        title: 'Device List',
        route: 'apps-device-list-view',
      },
      {
        title: 'Energy Trend',
        route: 'apps-energy-trend-view',
      },
      {
        title: 'Daily Record',
        route: 'apps-daily-record-view',
      },
      {
        title: 'Monthly Record',
        route: 'apps-monthly-record-view',
      },
      {
        title: 'Yearly Record',
        route: 'apps-yearly-record-view',
      },
      {
        title: 'Energy Raw Data',
        route: 'apps-energy-raw-data-view',
      },
      {
        title: 'Energy Table',
        route: 'apps-energy-table-view',
      },
      {
        title: 'Alarm Data',
        route: 'apps-alarm-data-view',
      },
    ],
  },
  // {
  //   header: 'Mold Mapping',
  // },
  {
    title: 'Mold Mapping',
    icon: 'ApertureIcon',
    children: [
      {
        title: 'Energy By Mold Monitoring',
        route: 'apps-energy-mold-view',
      },
      {
        // title: 'IM-Mold Heatmap',
        title: 'Heatmap',
        route: 'apps-mold-heatmap-view',
        // route: { name: 'apps-group-item-ctr-list', params: { id: 21 } },
      },
      {
        // title: 'IM-Mold Weekly Simulator',
        title: 'IM-Mold Simulator',
        route: 'apps-mold-simulator-list',
        // route: { name: 'apps-group-item-ctr-list', params: { id: 21 } },
      },
      {
        // title: 'IM-Mold Weekly Simulator',
        title: 'Weekly Simulator',
        route: 'apps-weekly-simulator-list',
        // route: { name: 'apps-group-item-ctr-list', params: { id: 21 } },
      },
    ],
  },
  // {
  //   header: 'Production',
  // },
  {
    title: 'Production',
    icon: 'FileMinusIcon',
    children: [
      {
        title: 'Overview',
        // icon: 'AirplayIcon',
        route: 'apps-production-overview-view',
      },
      {
        title: 'Issue',
        // icon: 'AirplayIcon',
        route: 'apps-issue-list',
      },
      {
        title: 'Weekly Data',
        // icon: 'AirplayIcon',
        route: 'apps-weekly-data-prod-list',
      },
      {
        title: 'CTR',
        // icon: 'FileMinusIcon',
        children: [
          {
            title: 'Group Item',
            route: 'apps-group-item-ctr-list',
          },
          {
            title: 'Item',
            route: 'apps-item-ctr-list',
            // route: { name: 'apps-group-item-ctr-list', params: { id: 21 } },
          },
          {
            title: 'Resep',
            route: 'apps-resep-ctr-list',
          },
          {
            title: 'Persentase Resep',
            route: 'apps-persen-resep-ctr-list',
          },
          {
            title: 'Silo',
            route: 'apps-silo-ctr-list',
          },
          {
            title: 'Data WIP',
            route: 'apps-wip-ctr-list',
          },
          {
            title: 'Stok Akhir',
            route: 'apps-stok-akhir-ctr-list',
          },
          {
            title: 'Laporan',
            route: 'apps-laporan-ctr-view',
          },
        ],
      },
      {
        title: 'CT Mixing',
        route: 'apps-ct-mixing-view',
      },
      {
        title: 'OEE',
        // icon: 'TrendingUpIcon',
        children: [
          {
            title: 'Split Downtime',
            route: 'apps-split-downtime-oee-list',
          },
          {
            title: 'Laporan',
            route: 'apps-laporan-oee-view',
          },
        ],
      },
      {
        title: 'Reject & Recycle',
        // icon: 'TrashIcon',
        route: 'apps-reject-view',
      },
      {
        title: 'Reject & Recycle (Preview)',
        // icon: 'TrashIcon',
        route: 'apps-reject-preview-view',
      },
    ],
  },
  // {
  //   header: 'Maintenance',
  // },
  {
    title: 'Maintenance',
    icon: 'ToolIcon',
    children: [
      {
        title: 'Overview',
        // icon: 'AirplayIcon',
        route: 'apps-maintenance-overview-view',
      },
      {
        title: 'Waiting Losses',
        route: 'apps-waiting-losses-view',
      },
      {
        title: 'Losses Preview',
        route: 'apps-losses-preview-view',
      },
      {
        title: 'Weekly Data',
        route: 'apps-weekly-data-mtn-list',
      },
      {
        title: 'Maintenance Cost',
        route: 'apps-maintenance-cost-view',
      },
      {
        title: 'Maintenance Cost Rawdata',
        route: 'apps-maintenance-cost-rawdata',
      },
      {
        title: 'Sparepart Value',
        route: 'apps-sparepart-value-view',
      },
      {
        title: 'Sparepart Value Rawdata',
        route: 'apps-sparepart-value-rawdata',
      },
    ],
  },
  // {
  //   header: 'SCM',
  // },
  {
    title: 'SCM',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Overview',
        route: 'apps-scm-overview-view',
      },
      {
        title: 'Daily Data',
        // icon: 'FileTextIcon',
        route: 'apps-daily-data-view',
      },
      {
        title: 'Weekly Data',
        route: 'apps-weekly-data-scm-list',
      },
      {
        title: 'CTPP',
        // icon: 'FileTextIcon',
        children: [
          {
            title: 'Master Mold',
            route: 'apps-mold-ctpp-list',
          },
          {
            title: 'Data Planning Fitting',
            route: 'apps-planning-ctpp-list',
          },
          {
            title: 'Data Planning Pipa',
            route: 'apps-planning-ctpp-pipa-list',
          },
          {
            title: 'Laporan',
            route: 'apps-laporan-ctpp-view',
          },
        ],
      },
    ],
  },
  // {
  //   header: 'QA',
  // },
  {
    title: 'QA',
    icon: 'CheckCircleIcon',
    children: [
      {
        title: 'Overview',
        route: 'apps-qa-overview-view',
        // route: 'misc-coming-soon',
      },
      {
        title: 'Weekly Data',
        route: 'apps-weekly-data-qa-list',
      },
      {
        title: 'Mixing',
        route: 'apps-qa-mixing-view',
        // route: 'misc-coming-soon',
      },
      {
        title: 'Mixing Azo',
        route: 'apps-qa-mixing-azo-view',
        // route: 'misc-coming-soon',
      },
      {
        title: 'Pelletizer',
        route: 'apps-qa-pelletizer-view',
        // route: 'misc-coming-soon',
      },
      {
        title: 'Injection Moulding',
        route: 'apps-qa-injection-moulding-view',
        // route: 'misc-coming-soon',
      },
      {
        title: 'IM Setcard',
        route: 'apps-qa-setcard-view',
        // route: 'misc-coming-soon',
      },
      {
        title: 'Weighter Result',
        route: 'apps-weighter-result-qa-view',
        // route: 'misc-coming-soon',
      },
      {
        title: 'Weighter Raw Data',
        route: 'apps-weighter-rowdata-qa-view',
      },
      {
        title: 'FG Weighter Mode Chart',
        route: 'apps-fg-weighter-chart-view',
      },
      {
        title: 'FG Std Summary',
        route: 'apps-fg-std-summary-view',
      },
      {
        title: 'FG Std Summary Mold',
        route: 'apps-fg-std-summary-mold-view',
      },
      {
        title: 'FG Summary Overall',
        route: 'apps-fg-summary-overall-view',
      },
    ],
  },
  {
    title: 'Log Report',
    icon: 'ListIcon',
    children: [
      {
        title: 'Report Detail',
        route: 'apps-log-report-detail-list',
        // route: 'misc-coming-soon',
      },
      {
        title: 'Report Summary',
        route: 'apps-log-report-summary-list',
      },
      {
        title: 'Report By Date',
        route: 'apps-log-report-date-list',
        // route: 'misc-coming-soon',
      },
    ],
  },
  {
    title: 'Limit Setting',
    icon: 'SettingsIcon',
    route: 'apps-limit-setting-list',
  },
  {
    title: 'Chatbot Setting',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Chatbot Setting',
        route: 'apps-chatbot-setting-list',
        // route: 'misc-coming-soon',
      },
      {
        title: 'Log Chatbot',
        route: 'apps-chatbot-log',
      },
    ],
  },
]
