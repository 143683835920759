/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      // { title: 'Home', route: { name: 'apps-home' }, icon: 'HomeIcon', isBookmarked: false },
      { title: 'Dashboard', route: { name: 'apps-dashboard-view' }, icon: 'MonitorIcon', isBookmarked: false },
      { title: 'Monthly Summary', route: { name: 'apps-monthly-summary-view' }, icon: 'MonitorIcon', isBookmarked: false },
      { title: 'Production - Group Item CTR', route: { name: 'apps-group-item-ctr-list' }, icon: 'FileMinusIcon', isBookmarked: false },
      { title: 'Production - Item CTR', route: { name: 'apps-item-ctr-list' }, icon: 'FileMinusIcon', isBookmarked: false },
      { title: 'Production - Resep CTR', route: { name: 'apps-resep-ctr-list' }, icon: 'FileMinusIcon', isBookmarked: false },
      { title: 'Production - Presentase Resep CTR', route: { name: 'apps-persen-resep-ctr-list' }, icon: 'FileMinusIcon', isBookmarked: false },
      { title: 'Production - Silo CTR', route: { name: 'apps-silo-ctr-list' }, icon: 'FileMinusIcon', isBookmarked: false },
      { title: 'Production - Stok Akhir CTR', route: { name: 'apps-stok-akhir-ctr-list' }, icon: 'FileMinusIcon', isBookmarked: false },
      { title: 'Production - Laporan CTR', route: { name: 'apps-laporan-ctr-view' }, icon: 'FileMinusIcon', isBookmarked: false },
      { title: 'Production - Split Downtime OEE', route: { name: 'apps-split-downtime-oee-list' }, icon: 'TrendingUpIcon', isBookmarked: false },
      { title: 'Production - Laporan OEE', route: { name: 'apps-laporan-oee-view' }, icon: 'TrendingUpIcon', isBookmarked: false },
      { title: 'Production -  Reject & Recycle', route: { name: 'apps-reject-view' }, icon: 'TrashIcon', isBookmarked: false },
      { title: 'Production -  Reject & Recycle (Preview)', route: { name: 'apps-reject-preview-view' }, icon: 'TrashIcon', isBookmarked: false },
      { title: 'Production - Overview', route: { name: 'apps-production-overview-view' }, icon: 'TrendingUpIcon', isBookmarked: false },
      { title: 'Production - Issues', route: { name: 'apps-issue-list' }, icon: 'TrendingUpIcon', isBookmarked: false },
      { title: 'Production - Weekly Data', route: { name: 'apps-weekly-data-prod-list' }, icon: 'TrendingUpIcon', isBookmarked: false },
      { title: 'Production - CT Mixing', route: { name: 'apps-ct-mixing-view' }, icon: 'TrendingUpIcon', isBookmarked: false },
      { title: 'Realtime Monitoring - CT Monitoring', route: { name: 'apps-ct-monitoring-view' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'Realtime Monitoring - Realtime Monitoring', route: { name: 'apps-realtime-monitoring-view' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'Realtime Monitoring - Best CT', route: { name: 'apps-best-ct-monitoring-view' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'Realtime Monitoring - Shiftly Result', route: { name: 'apps-shiftly-result-view' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'Realtime Monitoring - Grafik MES', route: { name: 'apps-grafik-mes-view' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'Realtime Monitoring - Shot Data', route: { name: 'apps-shot-data-view' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'Realtime Monitoring - Monthly Result', route: { name: 'apps-monthly-result-view' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'Realtime Monitoring - Plasticzing & CT', route: { name: 'apps-plasticzing-monitoring-view' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'Realtime Monitoring - CTPP Progress', route: { name: 'apps-ctpp-progress-view' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'Realtime Monitoring - Master Lampu', route: { name: 'apps-master-lampu-list' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'Realtime Monitoring - Chillers', route: { name: 'apps-chiller-list' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'Realtime Monitoring - Akses Lampu', route: { name: 'apps-akses-lampu-list' }, icon: 'ActivityIcon', isBookmarked: false },
      { title: 'SCM - Master Mold CTPP', route: { name: 'apps-mold-ctpp-list' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'SCM - Data Planning Fitting', route: { name: 'apps-planning-ctpp-list' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'SCM - Data Planning Pipa', route: { name: 'apps-planning-ctpp-pipa-list' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'SCM - Laporan CTTP', route: { name: 'apps-laporan-ctpp-view' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'SCM - Overview', route: { name: 'apps-scm-overview-view' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'SCM - Daily Data', route: { name: 'apps-daily-data-view' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'SCM - Weekly Data', route: { name: 'apps-weekly-data-scm-list' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'MES Rawdata - Powermeter', route: { name: 'apps-powermeter-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - Mixing', route: { name: 'apps-mixing-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - IM Log', route: { name: 'apps-im-log-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - Material Transport', route: { name: 'apps-material-transport-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - Tag Logger', route: { name: 'apps-tag-logger-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - Master Produk', route: { name: 'apps-master-produk-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - Master Mold', route: { name: 'apps-master-mold-raw-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - Reject Line 1-2', route: { name: 'apps-reject-line-12-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - Reject Line 3-5', route: { name: 'apps-reject-line-35-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - Reject Overflow', route: { name: 'apps-reject-overflow-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - Master Reject Type', route: { name: 'apps-reject-type-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - Master Mesin', route: { name: 'apps-master-mesin-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - Master Kardus', route: { name: 'apps-master-kardus-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - Pelletizer', route: { name: 'apps-pelletizer-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - Recycle/Unrecycle', route: { name: 'apps-recycle-unrecycle-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'MES Rawdata - Result Dynamic', route: { name: 'apps-result-dynamic-view' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'QA - Weighter Result', route: { name: 'apps-weighter-result-qa-view' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'QA - Weighter Raw Data', route: { name: 'apps-weighter-rowdata-qa-view' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'QA - FG Weighter Result', route: { name: 'apps-fg-weighter-chart-view' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'QA - FG Std Summary', route: { name: 'apps-fg-std-summary-view' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'QA - FG Std Summary Mold', route: { name: 'apps-fg-std-summary-mold-view' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'QA - FG Summary Overall', route: { name: 'apps-fg-summary-overall-view' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'QA - Overview', route: { name: 'apps-qa-overview-view' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'QA - Mixing', route: { name: 'apps-qa-mixing-view' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'QA - Mixing Azo', route: { name: 'apps-qa-mixing-azo-view' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'QA - Pelletizer', route: { name: 'apps-qa-pelletizer-view' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'QA - IM Setcard', route: { name: 'apps-qa-setcard-view' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'QA - Weekly Data', route: { name: 'apps-weekly-data-qa-list' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'Energy - SLD Overview', route: { name: 'apps-sld-overview-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Powermeter Overview', route: { name: 'apps-powermeter-overview-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Energy Report', route: { name: 'apps-energy-report-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Energy Report YTD', route: { name: 'apps-energy-report-ytd-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Energy Report Tag', route: { name: 'apps-energy-report-tag-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Tag Setting', route: { name: 'apps-tag-setting-list' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Energy Report Tag YTD', route: { name: 'apps-energy-report-tag-ytd-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Device List', route: { name: 'apps-device-list-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Energy Trend', route: { name: 'apps-energy-trend-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Daily Record', route: { name: 'apps-daily-record-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Monthly Record', route: { name: 'apps-daily-record-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Yearly Record', route: { name: 'apps-yearly-record-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Energy Raw Data', route: { name: 'apps-energy-raw-data-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Energy Table', route: { name: 'apps-energy-table-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Alarm Data', route: { name: 'apps-alarm-data-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Improvement Monitor', route: { name: 'apps-improvement-monitor-view' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Target Energy', route: { name: 'apps-target-energy-list' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Energy - Energy Before MES', route: { name: 'apps-energy-before-mes-list' }, icon: 'ZapIcon', isBookmarked: false },
      { title: 'Mold Mapping - Energy By Mold Monitoring', route: { name: 'apps-energy-mold-view' }, icon: 'ApertureIcon', isBookmarked: false },
      { title: 'Mold Mapping - IM-Mold Heatmap', route: { name: 'apps-mold-heatmap-view' }, icon: 'ApertureIcon', isBookmarked: false },
      { title: 'Mold Mapping - IM-Mold Simulator', route: { name: 'apps-mold-simulator-list' }, icon: 'ApertureIcon', isBookmarked: false },
      { title: 'Mold Mapping - IM-Mold Weekly Simulator', route: { name: 'apps-weekly-simulator-list' }, icon: 'ApertureIcon', isBookmarked: false },
      { title: 'Maintenance - Overview', route: { name: 'apps-maintenance-overview-view' }, icon: 'ToolIcon', isBookmarked: false },
      { title: 'Maintenance - Waiting Losses', route: { name: 'apps-waiting-losses-view' }, icon: 'ToolIcon', isBookmarked: false },
      { title: 'Maintenance - Losses Preview', route: { name: 'apps-losses-preview-view' }, icon: 'ToolIcon', isBookmarked: false },
      { title: 'Maintenance - Maintenance Cost', route: { name: 'apps-maintenance-cost-view' }, icon: 'ToolIcon', isBookmarked: false },
      { title: 'Maintenance - Maintenance Cost Raw Data', route: { name: 'apps-maintenance-cost-rawdata' }, icon: 'ToolIcon', isBookmarked: false },
      { title: 'Maintenance - Sparepart Value Raw Data', route: { name: 'apps-sparepart-value-rawdata' }, icon: 'ToolIcon', isBookmarked: false },
      { title: 'Maintenance - Sparepart Value', route: { name: 'apps-sparepart-value-view' }, icon: 'ToolIcon', isBookmarked: false },
      { title: 'Maintenance - Sparepart Value Raw Data', route: { name: 'apps-sparepart-value-rawdata' }, icon: 'ToolIcon', isBookmarked: false },
      { title: 'Maintenance - Weekly Data', route: { name: 'apps-weekly-data-mtn-list' }, icon: 'ToolIcon', isBookmarked: false },
      { title: 'Parameter & Limit Setting', route: { name: 'apps-limit-setting-list' }, icon: 'SettingsIcon', isBookmarked: false },
      { title: 'Log Report Detail', route: { name: 'apps-log-report-detail-list' }, icon: 'ListIcon', isBookmarked: false },
      { title: 'Log Report Summary', route: { name: 'apps-log-report-summary-list' }, icon: 'ListIcon', isBookmarked: false },
      { title: 'Parameter & Limit Setting', route: { name: 'apps-limit-setting-list' }, icon: 'SettingsIcon', isBookmarked: false },
      { title: 'Chatbot Setting', route: { name: 'apps-chatbot-setting-list' }, icon: 'SettingsIcon', isBookmarked: false },
      { title: 'Chatbot Log', route: { name: 'apps-chatbot-log' }, icon: 'SettingsIcon', isBookmarked: false },

    ],
  },
  // files: {
  //   key: 'file_name',
  //   data: [
  //     {
  //       file_name: "Joe's CV",
  //       from: 'Stacy Watson',
  //       icon: require('@/assets/images/icons/doc.png'),
  //       size: '1.7 mb',
  //     },
  //     {
  //       file_name: 'Passport Image',
  //       from: 'Ben Sinitiere',
  //       icon: require('@/assets/images/icons/jpg.png'),
  //       size: ' 52 kb',
  //     },
  //     {
  //       file_name: 'Questions',
  //       from: 'Charleen Patti',
  //       icon: require('@/assets/images/icons/doc.png'),
  //       size: '1.5 gb',
  //     },
  //     {
  //       file_name: 'Parenting Guide',
  //       from: 'Doyle Blatteau',
  //       icon: require('@/assets/images/icons/doc.png'),
  //       size: '2.3 mb',
  //     },
  //     {
  //       file_name: 'Class Notes',
  //       from: 'Gwen Greenlow',
  //       icon: require('@/assets/images/icons/doc.png'),
  //       size: ' 30 kb',
  //     },
  //     {
  //       file_name: 'Class Attendance',
  //       from: 'Tom Alred',
  //       icon: require('@/assets/images/icons/xls.png'),
  //       size: '52 mb',
  //     },
  //     {
  //       file_name: 'Company Salary',
  //       from: 'Nellie Dezan',
  //       icon: require('@/assets/images/icons/xls.png'),
  //       size: '29 mb',
  //     },
  //     {
  //       file_name: 'Company Logo',
  //       from: 'Steve Sheldon',
  //       icon: require('@/assets/images/icons/jpg.png'),
  //       size: '1.3 mb',
  //     },
  //     {
  //       file_name: 'Crime Rates',
  //       from: 'Sherlock Holmes',
  //       icon: require('@/assets/images/icons/xls.png'),
  //       size: '37 kb',
  //     },
  //     {
  //       file_name: 'Ulysses',
  //       from: 'Theresia Wrenne',
  //       icon: require('@/assets/images/icons/pdf.png'),
  //       size: '7.2 mb',
  //     },
  //     {
  //       file_name: 'War and Peace',
  //       from: 'Goldie Highnote',
  //       icon: require('@/assets/images/icons/pdf.png'),
  //       size: '10.5 mb',
  //     },
  //     {
  //       file_name: 'Vedas',
  //       from: 'Ajay Patel',
  //       icon: require('@/assets/images/icons/pdf.png'),
  //       size: '8.3 mb',
  //     },
  //     {
  //       file_name: 'The Trial',
  //       from: 'Sirena Linkert',
  //       icon: require('@/assets/images/icons/pdf.png'),
  //       size: '1.5 mb',
  //     },
  //   ],
  // },
  // contacts: {
  //   key: 'name',
  //   data: [
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
  //       name: 'Rena Brant',
  //       email: 'nephrod@preany.co.uk',
  //       time: '21/05/2019',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
  //       name: 'Mariano Packard',
  //       email: 'seek@sparaxis.org',
  //       time: '14/01/2018',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-24.jpg'),
  //       name: 'Risa Montufar',
  //       email: 'vagary@unblist.org',
  //       time: '10/08/2019',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-15.jpg'),
  //       name: 'Maragaret Cimo',
  //       email: 'designed@insanely.net',
  //       time: '01/12/2019',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
  //       name: 'Jona Prattis',
  //       email: 'unwieldable@unblist.org',
  //       time: '21/05/2019',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-5.jpg'),
  //       name: 'Edmond Chicon',
  //       email: 'museist@anaphyte.co.uk',
  //       time: '15/11/2019',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
  //       name: 'Abbey Darden',
  //       email: 'astema@defectively.co.uk',
  //       time: '07/05/2019',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-10.jpg'),
  //       name: 'Seema Moallankamp',
  //       email: 'fernando@storkish.co.uk',
  //       time: '13/08/2017',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
  //       name: 'Charleen Warmington',
  //       email: 'furphy@cannibal.net',
  //       time: '11/08/1891',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
  //       name: 'Geri Linch',
  //       email: 'insignia@markab.org',
  //       time: '18/01/2015',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-23.jpg'),
  //       name: 'Shellie Muster',
  //       email: 'maxillary@equalize.co.uk',
  //       time: '26/07/2019',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
  //       name: 'Jesenia Vanbramer',
  //       email: 'hypotony@phonetist.net',
  //       time: '12/09/2017',
  //     },
  //     {
  //       img: require('@/assets/images/portrait/small/avatar-s-23.jpg'),
  //       name: 'Mardell Channey',
  //       email: 'peseta@myrica.com',
  //       time: '11/11/2019',
  //     },
  //   ],
  // },
}
/* eslint-enable */
