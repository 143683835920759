<template>
  <p class="clearfix mb-0">
    <!-- <span class="float-md-left d-block d-md-inline-block mt-25">Copyright  © 2022 IT DBC<span class="d-none d-sm-inline-block"></span> -->

    <span class="float-md-left d-none d-md-block">Copyright  © 2022 IT DBC</span>
  </p>
</template>

<script>
// import { BLink } from 'bootstrap-vue'

export default {
  components: {
    // BLink,
  },
}
</script>
